@use 'assets/styles/utils/helper' as h;
@use './theme.tokens.scss';

.liveBlog {
  background-color: var(--lb--background-color);
  position: relative;
  z-index: h.get-z-index('page');

  &--hasStickyVideo {
    z-index: h.get-z-index('page') + 1;
  }
}

// TODO: Refactor when looking at LiveBlog Component
:global(.LiveBlog section.related),
:global(.Blog section.related) {
  width: auto;
  margin-left: 0;
}
