@use 'assets/styles/utils/helper' as h;

.liveBlog {
  --lb--background-color: var(--default-background);

  :global(body.msnbc) & {
    --lb--stylized-header--color: var(--white);
  }

  :global(body.today) & {
    --lb--background-color: var(--white);
  }
}
